import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// UTC时间转为北京时间
const convertToBeijingTime = (utcTime) => {
    if (!utcTime) return '无时间信息';
    const date = new Date(utcTime);
  
    // 获取 UTC 时间戳并加上8小时的偏移量 (8 * 60 * 60 * 1000 毫秒)
    const beijingTime = new Date(date.getTime() + 8 * 60 * 60 * 1000);
  
    // 格式化为 yyyy-MM-dd HH:mm:ss 的北京时间格式
    return beijingTime.toLocaleString('zh-CN', { hour12: false });
};

// 解析域名状态并转换为中文
const parseDomainStatus = (statusArray) => {
    if (!Array.isArray(statusArray)) {
      return '无状态信息';
    }
  
    const statusMap = {
      "ok": "正常 (ok)",
      "clientDeleteProhibited": "客户端禁止删除 (clientDeleteProhibited)",
      "clientTransferProhibited": "客户端禁止转移 (clientTransferProhibited)",
      "clientUpdateProhibited": "客户端禁止更新 (clientUpdateProhibited)",
      "serverDeleteProhibited": "服务器禁止删除 (serverDeleteProhibited)",
      "serverTransferProhibited": "服务器禁止转移 (serverTransferProhibited)",
      "serverUpdateProhibited": "服务器禁止更新 (serverUpdateProhibited)",
    };
  
    return statusArray.map((statusObj) => {
      const statusKey = statusObj.status;
      return statusMap[statusKey] || `未知状态 (${statusKey})`;
    }).join(', ');
};

// 解析DNSSEC状态的中文说明
const parseDnssecStatus = (dnssec) => {
    if (!dnssec) return '未知状态';
    
    switch (dnssec.toLowerCase()) {
      case 'unsigned':
        return '未签名 (unsigned)';
      case 'signed':
        return '已签名 (signed)';
      default:
        return `未知状态 (${dnssec})`;
    }
};

function IPQuery() {
  const [ipOrDomain, setIpOrDomain] = useState(''); // 默认输入框值为空
  const [queryType, setQueryType] = useState('ip'); // 默认查询类型为 IP 查询
  const [captchaToken, setCaptchaToken] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState(''); // 模态框消息
  const [showCaptcha, setShowCaptcha] = useState(false); // 控制模态框的显示
  const [lastUpdate, setLastUpdate] = useState(''); // 存储IP数据更新时间
  const [showModal, setShowModal] = useState(false); // 控制提示弹窗的显示

  const recaptchaRef = useRef();

  // 获取请求者的真实IP地址和数据更新时间
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [ipResponse, updateTimeResponse] = await Promise.all([
          axios.get('https://openapi.ip.setonink.com/get-real-ip'),
          axios.get('https://openapi.ip.setonink.com/data-update-time')
        ]);
        setIpOrDomain(ipResponse.data.ip); // 设置IP到输入框中
        const date = new Date(updateTimeResponse.data.lastModified);
        setLastUpdate(date.toLocaleString());
      } catch (error) {
        console.error('获取数据失败:', error);
        setLastUpdate('无法获取更新时间');
      }
    };
    fetchInitialData();
  }, []);

  // 打开提示模态框
  const openModal = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  const handleQuery = () => {
    if (!ipOrDomain) {
      openModal('请输入IP地址或域名');
      return;
    }
    
    // 校验输入
    if (queryType === 'whois' && !/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(ipOrDomain)) {
      openModal('请输入有效的域名');
      return;
    }

    setShowCaptcha(true); // 显示reCAPTCHA验证模态框
  };

  const handleCaptchaSubmit = async () => {
    if (!captchaToken) {
      openModal('请完成reCAPTCHA验证');
      return;
    }

    setLoading(true);
    try {
      let response;

      // 根据查询类型调用不同的接口
      if (queryType === 'ip') {
        response = await axios.get('https://openapi.ip.setonink.com/query-ip-secure', {
          params: { ip: ipOrDomain, captchaToken }
        });
        const data = response.data.data; // 获取响应中的 data 对象
        setResult({
          'IP地址': data.ip || '暂无数据，正在努力更新中...',
          'IP属地': `${data.country?.name || '暂无数据'} ${data.regions?.join('') || ''}`,
          '网段': data.addr || '暂无数据，正在努力更新中...',
          'AS信息': `AS${data.as?.number || '暂无数据'} - ${data.as?.name || '暂无数据'}`,
          'IP类型': data.type || '暂无数据，正在努力更新中...'
        });
      } else if (queryType === 'whois') {
        // Whois 域名查询接口
        response = await axios.get('https://openapi.ip.setonink.com/whois-query', {
          params: { domain: ipOrDomain.toLowerCase(), captchaToken }
        });
        const data = response.data;

        // 确保 `status` 和 `nameServers` 是数组，并且有数据
        const status = Array.isArray(data.status) ? data.status : [];
        const nameServers = Array.isArray(data.nameServers) ? data.nameServers : [];

        setResult({
          '域名': data.domain.toLowerCase() || '暂无数据',
          '注册商': data.registrar || '暂无数据',
          '注册商网址': data.registrarURL || '暂无数据',
          'Whois服务器': data.whoisServer || '暂无数据',
          '更新时间': convertToBeijingTime(data.updatedDate) || '暂无数据',
          '创建时间': convertToBeijingTime(data.creationDate) || '暂无数据',
          '过期时间': convertToBeijingTime(data.expirationDate) || '暂无数据',
          '状态': status.length > 0 ? status.map(s => parseDomainStatus([s]) || '暂无数据') : '暂无数据',
          '状态说明地址': status.length > 0 ? status.map(s => s.url || '暂无链接') : '暂无数据',
          'DNS服务器': nameServers.length > 0 ? nameServers.map(ns => ns.toLowerCase()) : ['暂无数据'],
          '注册人电话': data.registrantPhone || '暂无数据',
          '注册人邮箱': data.registrantEmail || '暂无数据',
          'DNSSEC': parseDnssecStatus(data.dnssec) || '暂无数据',
        });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || '查询失败，请稍后再试';
      openModal(`查询失败：${errorMessage}`);
    } finally {
      setLoading(false);
      recaptchaRef.current.reset(); // 重置reCAPTCHA
      setShowCaptcha(false); // 关闭模态框
    }
  };

  // 处理查询类型切换的函数
const handleQueryTypeChange = (e) => {
  setQueryType(e.target.value);
  setIpOrDomain(''); // 切换查询类型时清空输入框内容
};

return (
  <div className="container mt-5">
    <h1 className="text-center mb-4">IP/域名查询</h1>
    <p className="text-center">选择查询类型并输入IP地址或域名，通过Google验证进行查询</p>

    {/* 公告部分 */}
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="alert alert-info text-center">
          <strong>公告：</strong> 壹盾综合数据平台已上线，可购买套餐使用，价格实惠！
        </div>
      </div>
    </div>

    {/* 查询类型选择框 */}
    <div className="row justify-content-center mb-3">
      <div className="col-md-6">
        <select
          className="form-select form-select-lg"
          value={queryType}
          onChange={handleQueryTypeChange} // 绑定切换类型函数
        >
          <option value="ip">IP 地址查询</option>
          <option value="whois">域名 Whois 查询</option>
        </select>
      </div>
    </div>

    {/* 输入框 */}
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="mb-3">
          <input
            type="text"
            value={ipOrDomain}
            onChange={(e) => setIpOrDomain(e.target.value)}
            placeholder={queryType === 'ip' ? '输入IP地址' : '输入域名'}
            className="form-control form-control-lg"
          />
        </div>
        <div className="d-grid gap-2">
          <button onClick={handleQuery} className="btn btn-primary btn-lg" disabled={loading}>
            {loading ? '查询中...' : '查询'}
          </button>
        </div>
      </div>
    </div>

      {/* 查询结果部分 */}
      {result && (
        <div className="mt-4">
          <h2 className="text-center">查询结果</h2>
          <table className="table table-bordered">
            <tbody>
              {Object.entries(result).map(([key, value]) => (
                (key === 'DNS服务器' || key === '状态' || key === '状态说明地址') && Array.isArray(value) ? (
                  <React.Fragment key={key}>
                    <tr>
                      <td rowSpan={value.length} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                        <strong>{key}</strong>
                      </td>
                      <td>{value[0]}</td>
                    </tr>
                    {value.slice(1).map((item, index) => (
                      <tr key={`${key}-${index}`}>
                        <td>{item}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ) : (
                  <tr key={key}>
                    <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                      <strong>{key}</strong>
                    </td>
                    <td>{value}</td>
                  </tr>
                )
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* 开放API调用说明部分 */}
      <div className="mt-5">
        <h2>开放API调用说明</h2>
        <p>您可以通过以下开放API进行查询，查询次数不限，每分钟限制30次调用：</p>
        <pre className="bg-light p-3 border rounded">
          GET https://openapi.ip.setonink.com/query-ip?ip=IPv4/IPv6<br />
          返回示例：<br />
          &#123;<br />
          &nbsp;&nbsp;"ret": "ok",<br />
          &nbsp;&nbsp;"data": &#123;<br />
          &nbsp;&nbsp;&nbsp;&nbsp;"ip": "116.4.102.1",<br />
          &nbsp;&nbsp;&nbsp;&nbsp;"as": &#123;"number": 4134, "name": "Chinanet", "info": "中国电信"&#125;,<br />
          &nbsp;&nbsp;&nbsp;&nbsp;"addr": "116.4.96.0/21",<br />
          &nbsp;&nbsp;&nbsp;&nbsp;"country": &#123;"code": "CN", "name": "中国"&#125;,<br />
          &nbsp;&nbsp;&nbsp;&nbsp;"registered_country": &#123;"code": "CN", "name": "中国"&#125;,<br />
          &nbsp;&nbsp;&nbsp;&nbsp;"regions": &#91;"广东省", "东莞市"&#93;,<br />
          &nbsp;&nbsp;&nbsp;&nbsp;"regions_short": &#91;"广东", "东莞"&#93;,<br />
          &nbsp;&nbsp;&nbsp;&nbsp;"type": "宽带"<br />
          &nbsp;&nbsp;&#125;<br />
          &#125;
        </pre>
        <div className="text-center mt-4">
          <a href="https://user.ip.setonink.com/" className="btn btn-outline-info mx-2" rel="noopener noreferrer">壹盾综合数据平台</a>
          <a href="https://user.ip.setonink.com/docs" className="btn btn-outline-info mx-2" rel="noopener noreferrer">付费接口文档</a>
          <a href="https://user.ip.setonink.com/ip-correction" className="btn btn-outline-info mx-2" rel="noopener noreferrer">IP信息纠错</a>
        </div>
      </div>

      {/* 提前加载Google reCAPTCHA */}
      <div style={{ visibility: 'hidden', height: 0 }}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LeLbw4qAAAAAIPFN7f3NrPVosRGLlDtt3gkmHFn"
          onChange={setCaptchaToken}
          hl="zh-CN"
          scriptURL="https://recaptcha.net/recaptcha/api.js"
        />
      </div>

      {/* Google reCAPTCHA 验证模态框 */}
      <div
        className={`modal fade ${showCaptcha ? 'show' : ''}`}
        style={{ display: showCaptcha ? 'block' : 'none' }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="captchaModalLabel"
        aria-hidden={!showCaptcha}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="captchaModalLabel">Google reCAPTCHA 验证</h5>
              <button type="button" className="btn-close" onClick={() => setShowCaptcha(false)}></button>
            </div>
            <div className="modal-body d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LeLbw4qAAAAAIPFN7f3NrPVosRGLlDtt3gkmHFn"
                onChange={setCaptchaToken}
                hl="zh-CN"
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={handleCaptchaSubmit}>确认并提交</button>
            </div>
          </div>
        </div>
      </div>

      {/* 提示模态框 */}
      <div
        className={`modal fade ${showModal ? 'show' : ''}`}
        style={{ display: showModal ? 'block' : 'none' }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalLabel"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="errorModalLabel">提示信息</h5>
              <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
            </div>
            <div className="modal-body">
              {modalMessage}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>确定</button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer 区域 */}
      <footer className="mt-5 pt-3 border-top text-center">
  <p className="text-muted" style={{ fontSize: '0.9rem' }}>IP数据更新时间：{lastUpdate}</p>

  {/* 友情链接部分 */}
  <p className="mt-1 mb-1 d-inline">友情链接：</p>
  <span className="d-inline">
    <a href="https://www.1yidc.com" target="_blank" rel="noopener" className="text-muted mx-2">高防服务器</a> |
    <a href="https://www.11dun.com" target="_blank" rel="noopener" className="text-muted mx-2">高防CDN</a> |
    <a href="https://www.aiboce.com" target="_blank" rel="noopener" className="text-muted mx-2">在线TCPING</a>
  </span>

  <p>&copy; 2024 南京思顿软件科技有限公司. All rights reserved.</p>
  
  <p className="text-muted d-inline-block mx-2">
    <a href="https://dxzhgl.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%25E5%258D%2597%25E4%25BA%25AC%25E6%2580%259D%25E9%25A1%25BF%25E8%25BD%25AF%25E4%25BB%25B6%25E7%25A7%2591%25E6%258A%2580%25E6%259C%2589%25E9%2599%2590%25E5%2585%25AC%25E5%258F%25B8&amp;type=xuke" target="_blank" rel="noopener">
      《增值电信业务经营许可证》编号：B1-20232617
    </a>
  </p>
  <span className="text-muted">|</span>
  <p className="text-muted d-inline-block mx-2">
    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener">苏ICP备2022048063号</a>
  </p>
  <span className="text-muted">|</span>
  <p className="text-muted d-inline-block mx-2">
    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32011502011761" target="_blank" rel="noopener">苏公网安备32011502011761号</a>
  </p>
</footer>
    </div>
  );
}

export default IPQuery;
